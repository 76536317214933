import React from "react"
import { Wrapper } from "./style"
import { Text } from "components/ui"

const TextEg = () => {
  return (
    <Wrapper>
      <Text as="h1">Text Component</Text>
      <Text as="h1">Text h1</Text>
      <Text as="h2">Text h2</Text>
      <Text as="h3">Text h3</Text>
      <Text as="h4">Text h4</Text>
      <Text as="h5">Text h5</Text>
      <Text as="h6">Text h6</Text>
      <Text as="p">Text paragraph</Text>
      <Text as="h2" fontSize={[80]}>Text h2 styled as h1</Text>
    </Wrapper>
  )
}

export default TextEg
