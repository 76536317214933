import React, { useState } from "react"
import { ReactComponent as PhoneIcon } from "icons/phone.svg"

import { useForm, Controller } from "react-hook-form"
import {
  FormField,
  TextInput,
  RadioInput,
  Checkbox,
  Toggle,
  SelectInput,
  DEFAULT_ERROR_MESSAGES,
} from "ui/Form"
import { FormFieldsWrapper, RadioList } from "ui/Form/style"

import { joiResolver } from "@hookform/resolvers/joi"
import Joi from "joi"
import { Text } from "ui"
import { Button } from "../ui"

const enquiryOptions = [
  { value: "general-enquiry", label: "General enquiry" },
  { value: "plantations", label: "Plantations" },
  { value: "sawmill", label: "Sawmill" },
  { value: "sales", label: "Sales" },
  { value: "distribution", label: "Distribution" },
  { value: "1", label: "1" },
  { value: "2", label: "2" },
  { value: "3", label: "3" },
  { value: "4", label: "4" },
  { value: "5", label: "5" },
  { value: "6", label: "6" },
  { value: "7", label: "7" },
  { value: "8", label: "9" },
  { value: "9", label: "10" },
  { value: "10", label: "11" },
  
]

const FORM_SHAPE = Joi.object({
  fullName: Joi.string().required(),
  phone: Joi.string().required(),
  email: Joi.string().required(),
  message: Joi.string().allow(""),
  gender: Joi.string().required(),
  ingredients: Joi.array().required().min(1),
  enquiryType: Joi.object().required(),
  isCool: Joi.boolean().required(),
}).messages(DEFAULT_ERROR_MESSAGES)

const FormEg = () => {
  const [submitted, setSubmitted] = useState(false)

  const { errors, control, setValue, register, handleSubmit } = useForm({
    resolver: joiResolver(FORM_SHAPE),
  })

  console.log("errors:", errors)

  const onSubmit = (data) => {
    console.log("data:", data)
    setSubmitted(true)
  }

  return (
    <div
      style={{ border: "2px solid red", padding: "30px", margin: "30px 0 " }}
    >
      <form onSubmit={handleSubmit(onSubmit)}>
        <Text mb={30} as="h3">
          {submitted
            ? "thanks for the submission"
            : "please fill out the form below"}
        </Text>
        {!submitted && (
          <>
            <FormFieldsWrapper>
              <TextInput
                name="fullName"
                floatingLabel="Full name"
                ref={register}
                errors={errors}
              />

              <TextInput
                prefix={<PhoneIcon />}
                label="Email address"
                name="email"
                type="email"
                errors={errors}
                ref={register}
              />
              <TextInput
                label="Phone number"
                name="phone"
                type="phone"
                ref={register}
                errors={errors}
              />
              <TextInput
                label="Message"
                name="message"
                type="textarea"
                ref={register}
                errors={errors}
              />

              <FormField name="gender" label="Gender" errors={errors}>
                <RadioList>
                  <RadioInput name="gender" value="female" ref={register} />
                  <RadioInput name="gender" value="male" ref={register} />
                </RadioList>
              </FormField>

              <FormField name="ingredients" label="Ingredients" errors={errors}>
                <RadioList>
                  <Checkbox
                    name="ingredients"
                    value="broccoli"
                    ref={register}
                  />
                  <Checkbox name="ingredients" value="cheese" ref={register} />
                  <Checkbox name="ingredients" value="lemon" ref={register} />
                </RadioList>
              </FormField>
              <FormField
                name="enquiryType"
                label="Enquiry Type"
                errors={errors}
              >
                <Controller
                  name="enquiryType"
                  setValue={setValue}
                  as={SelectInput}
                  options={enquiryOptions}
                  placeholder="Choose an option..."
                  control={control}
                  maxWidth={500}
                />
              </FormField>

              <Toggle
                name="isCool"
                label="Are you a cool dude?"
                ref={register}
              />

              <Button type="submit">Submit</Button>
            </FormFieldsWrapper>
          </>
        )}
      </form>
    </div>
  )
}

export default FormEg
