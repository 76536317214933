import styled from "styled-components"
// import { device } from 'breakpoints';

export const DropdownContainer = styled.div`
  display: flex;
  flex-direction: column;
  transition: background 80ms;
  width: 100%;
  position: relative;
  border-radius: 5px;
`

export const DropdownTrigger = styled.button`
  display: flex;
  align-items: center;
  outline: none;
  border: none;
  background: ${(props) => props.theme.primaryColor};
  justify-content: space-between;
  cursor: pointer;
  height: 50px;
  padding: 0 20px;
  border-radius: inherit;
  border-bottom-left-radius: ${(props) => props.shown && 0};
  border-bottom-right-radius: ${(props) => props.shown && 0};

  svg {
    width: 16px;
    transform: ${(props) => (props.shown ? "rotateX(180deg);" : "none")};
    transition: 400ms;
    path {
      stroke: #fff;
    }
  }

  span {
    font-weight: 600;
    color: #fff;
  }

  :hover {
    background: ${(props) => props.theme.btnHoverColor};
  }
`

export const DropdownDisplay = styled.div`
  position: absolute;
  left: 0;
  bottom: 0;
  background: #fff;
  box-shadow: 10px 15px 35px 0 rgba(0, 0, 0, 0.35);
  z-index: 1;
  width: 100%;
  transform: translateY(100%);
  opacity: ${(props) => (props.shown ? 1 : 0)};
  pointer-events: ${(props) => (props.shown ? "inherit" : "none")};
  transition: 100ms;
  overflow: hidden;
  border-radius: inherit;
  border-top-left-radius: 0px;
  border-top-right-radius: 0px;

  > * {
    width: 100%;
    border-radius: inherit;
  }
`
