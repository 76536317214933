import React, { useState } from "react"
import { AccordionContainer } from "./style.js"

const Accordion = ({ className, children, allowMultipleOpen }) => {
  const [currentlyExpanded, setCurrentlyExpanded] = useState(null)

  const formattedChildren = allowMultipleOpen
    ? children
    : children?.map((child, index) => {
        return React.cloneElement(child, {
          key: index,
          setCurrentlyExpanded: setCurrentlyExpanded,
          currentlyExpanded: currentlyExpanded,
        })
      })

  return (
    <>
      <h3>i'm an accordion</h3>
      <AccordionContainer className={className}>
        {formattedChildren}
      </AccordionContainer>
    </>
  )
}

export default Accordion
