import React, { useState, useRef } from "react"
import { DropdownContainer, DropdownTrigger, DropdownDisplay } from "./style.js"
import { ReactComponent as DownArrow } from "./down-arrow.svg"
import { useClose } from "helpers/useClose"

const Dropdown = ({ className, children, label }) => {
  const [shown, setShown] = useState(false)
  const dropdownRef = useRef(null)

  const toggleOpen = () => {
    setShown(!shown)
  }

  useClose(dropdownRef, () => setShown(false))

  return (
    <DropdownContainer className={className} shown={shown} ref={dropdownRef}>
      <DropdownTrigger shown={shown} onClick={toggleOpen}>
        <span className="label">{label}</span>
        <DownArrow className="arrow" />
      </DropdownTrigger>

      <DropdownDisplay shown={shown}>{children}</DropdownDisplay>
    </DropdownContainer>
  )
}

export default Dropdown
