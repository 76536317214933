import React from "react"
import { Grid, Row, Col } from "ui/Grid"
import { ExtendedGrid, Item } from "./style"
import { MainColumn } from "ui/MainColumn"

const items = [1, 2, 3, 4, 5, 6, 7, 8, 9]
// c1={[800, 6]}
// c2={[1400, 6]}
const GridEg = () => {
  return (
    <MainColumn>
      <h3>
        you can set the horizontal & vertical alignment of the items in each Col
      </h3>
      <ExtendedGrid>
        <Row
          xsGap={10}
          smGap={20}
          mdGap={40}
          lgGap={100}
          xsRowGap={100}
          smRowGap={70}
          mdRowGap={40}
          lgRowGap={10}
        >
          <Col xs={12} sm={6} md={4} xsAlignX="start">
            <Item>
              <h2>xsAlignX="start"</h2>
              <p>
                Pellentesque habitant morbi tristique senectus et netus et
                malesuada fames ac turpis egestas. Vestibulum ante ipsum primis
                in faucibus orci luctus et ultrices posuere cubilia Curae; Fusce
                id purus. Sed aliquam ultrices mauris.
              </p>
            </Item>
          </Col>

          <Col xs={12} sm={6} md={4} xsAlignX="center">
            <Item>
              <h2>xsAlignX="center"</h2>{" "}
            </Item>
          </Col>

          <Col xs={12} sm={6} md={4} xsAlignX="end">
            <Item>
              <h2>xsAlignX="end"</h2>
            </Item>
          </Col>

          <Col xs={12} sm={6} md={4} xsAlignY="start">
            <Item>
              <h2>xsAlignY="start"</h2>
              <p>
                Pellentesque habitant morbi tristique senectus et netus et
                malesuada fames ac turpis egestas. Vestibulum ante ipsum primis
                in faucibus orci luctus et ultrices posuere cubilia Curae; Fusce
                id purus. Sed aliquam ultrices mauris.
              </p>
            </Item>
          </Col>

          <Col xs={12} sm={6} md={4} xsAlignY="center">
            <Item>
              <h2>xsAlignY="center"</h2>{" "}
            </Item>
          </Col>

          <Col xs={12} sm={6} md={4} xsAlignY="end">
            <Item>
              <h2>xsAlignY="end"</h2>
            </Item>
          </Col>

          {/* {items.slice(0, 3).map((item, index) => {
            return (
              <Col xs={12} sm={6} md={4} xsAlignX="end">
                <Item>
                  <h2>{item}</h2>
                </Item>
              </Col>
            )
          })} */}
        </Row>
      </ExtendedGrid>
    </MainColumn>
  )
}

export default GridEg
