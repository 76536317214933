import styled from "styled-components"
import { Grid, Row, Col } from "ui/Grid"

export const ExtendedGrid = styled(Grid)`
  border: 1px solid hotpink;
  margin: 30px auto;
`
export const Item = styled.div`
  border: 1px solid teal;
  h2 {
    font-size: 19px;
    margin-bottom: 10px;
  }
`
