import styled from "styled-components"
// import { device } from 'breakpoints';

export const AccordionContainer = styled.div`
  width: 100%;
  padding: 30px;
  border: 2px solid hotpink;
`

export const AccordionItemWrapper = styled.div`
  border: 1px solid teal;
  margin-bottom: 20px;
`
export const AccordionTrigger = styled.button`
  display: flex;
  justify-content: space-between;
  align-items: center;
  cursor: pointer;
  width: 100%;
  padding: 20px 20px;
  z-index: 1;

  svg.arrow {
    width: 16px;
    height: 10px;
    transform: ${(props) => (props.expanded ? "scale(-1)" : "scale(1)")};
  }

  h3.title {
    font-size: 17px;
  }
`

export const AccordionDisplay = styled.div`
  display: ${(props) => (props.expanded ? "block" : "none")};
`
