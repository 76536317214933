import React, { useState } from "react"
import {
  SubscribeForm,
  SubscribePageContainer,
  SubscribeTitle,
  SubscribeSubtitle,
  SubmitButton,
} from "./style"
import { useForm } from "react-hook-form"
import { TextInput } from "ui/Form"
import gql from "graphql-tag"
import { useMutation } from "@apollo/react-hooks"

const CREATE_SUBSCRIPTION = gql`
  mutation CreateSubscription($email: String!) {
    createSubscription(
      input: { clientMutationId: "Generic contact", email: $email }
    ) {
      data
      success
    }
  }
`

const EmailSubscription = () => {
  const [createSubscription] = useMutation(CREATE_SUBSCRIPTION)
  const [submitted, setSubmitted] = useState(false)
  const { handleSubmit, register, errors } = useForm()

  const onSubmit = (values) => {
    console.log("values:", values)
    setSubmitted(true)

    createSubscription({
      variables: {
        email: values.email.length ? values.email : "n/a",
      },
    })
  }

  return (
    <SubscribePageContainer>
      <SubscribeTitle>
        {submitted ? "Thanks for your subscription" : "Subscribe"}
      </SubscribeTitle>
      <SubscribeSubtitle submitted={submitted}>
        Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod.
      </SubscribeSubtitle>

      {!submitted && (
        <SubscribeForm onSubmit={handleSubmit(onSubmit)}>
          <p className="label">Email address</p>
          <TextInput
            name="email"
            type="email"
            placeholder="Your email address ..."
            ref={register}
            errors={errors}
          />
          <SubmitButton type="submit" label="Submit">
            Subscribe!
          </SubmitButton>
        </SubscribeForm>
      )}
    </SubscribePageContainer>
  )
}

export default EmailSubscription
