import React, { useContext } from "react"
import Layout from "components/layout"
import SEO from "components/seo"
import { Link } from "gatsby"
//UI elements
import { Grid } from "ui/Grid"
import { Teaser } from "ui/Teaser"
import { MainColumn } from "ui/MainColumn"
import { PopUpContext } from "context/PopUpContext"
import { PopUp } from "ui/PopUp"

import { Spacer } from "ui/Spacer"
import { Line } from "ui/Line"
import { Box, Button, Icon } from "ui"

//examples
import { SelectEg } from "components/examples/SelectEg"
import { GridEg } from "components/examples/GridEg"
import { DropdownEg } from "components/examples/DropdownEg"
import { AccordionEg } from "components/examples/AccordionEg"
import FormEg from "components/examples/FormEg"
import ContentTest from "components/examples/ContentTest"
import { EmblaCarouselEg } from "components/examples/EmblaCarouselEg"
import { TextEg } from "components/examples/TextEg"
import { ReactComponent as Phone } from "images/icons/phone.svg"
import { EmailSubscription } from "../components/ui/EmailSubscription"

const IndexPage = ({ pageContext }) => {
  const phoneIcon = <Phone />
  const { isPopUpOpen, setPopUpOpen } = useContext(PopUpContext)

  const togglePopUp = () => {
    setPopUpOpen(!isPopUpOpen)
  }
  const doSomething = () => {
    alert("something got did")
  }

  const accordionData = [
    { title: "title1", body: "body1" },
    { title: "title2", body: "body2" },
    { title: "title3", body: "body3" },
    { title: "title4", body: "body4" },
    { title: "title5", body: "body5" },
  ]

  return (
    <Layout pageContext={pageContext}>
      <Button
        icon={<Icon name="send" height={[18]} width={[25]} color="white" />}
      >
        Submit Message
      </Button>

      <Box background="#163C67" p={30} dir="row">
        <Button variant="white" mr={30}>
          Text Here
        </Button>
        <Button
          variant="white"
          icon={<Icon name="send" height={[18]} width={[25]} />}
        >
          Submit Message
        </Button>
      </Box>

      <FormEg />

      <SEO title="Home" />
      <GridEg />
      <EmblaCarouselEg />
      <MainColumn>
        <Box border={"3px solid #f37736"}>
          <Box as="h3">Box Component used as a h3</Box>
          <Box as="p">This is a grid component</Box>
        </Box>
        <AccordionEg />
        <PopUp />
        <Button
          noBackground
          label="pop up context trigger"
          onClick={togglePopUp}
        />
        <Button fullWidth icon={phoneIcon} label="Icon button" />
        <SelectEg />
        <DropdownEg label="Dropdown..." />
        <Grid cols={[1, 2, 3]} gap={[30, 40, 60]}>
          <Teaser
            row
            key="teaser1"
            title="This title has a link"
            excerpt="Theres no read more component defined"
            link="https://avenue.design/"
          ></Teaser>

          <Teaser
            row
            key="teaser2"
            title="Read More"
            excerpt="Don't pass in the link prop. Let the child component mange the links"
          >
            <Link
              to="https//avenue.design"
              style={{ marginTop: "20px", textDecoration: "underline" }}
            >
              read-more
            </Link>
            {/* <Phone style={{ marginTop: "20px" }} /> */}
          </Teaser>

          <Teaser
            key="teaser10"
            title="Column Teaser"
            excerpt="Don't pass in the row prop to stack. You can pass as many child components as you want"
          >
            <Link
              to="https//avenue.design"
              style={{ marginTop: "20px", textDecoration: "underline" }}
            >
              read-more
            </Link>
            <Phone style={{ marginTop: "20px" }} />
          </Teaser>
        </Grid>
        <TextEg />
        Adding a Spacer component below
        <Spacer height={[100, 200, 300]} />
        Adding a Line component below
        <Line />
        <ContentTest />
        <EmailSubscription />
      </MainColumn>
    </Layout>
  )
}

export default IndexPage
