import React, { useState } from "react"

import { CustomDropdown, RandomContent } from "./style"

const DropdownEg = () => {
  return (
    <CustomDropdown label="Click on me!">
      <RandomContent>
        <h1>Display anything here!!</h1>
      </RandomContent>
    </CustomDropdown>
  )
}

export default DropdownEg
