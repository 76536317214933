import styled from "styled-components"
// import { device } from 'breakpoints'
import { Dropdown } from "ui/Dropdown"

export const CustomDropdown = styled(Dropdown)`
  width: 600px;
  border-radius: 10px;

  button {
    height: 60px;
    background: orange;

    span {
      font-size: 20px;
    }

    svg {
      width: 20px;
    }

    :hover {
      background: red;
    }
  }
`

export const RandomContent = styled.div`
  background: teal;
  padding: 30px;

  h1 {
    color: white;
  }
`
