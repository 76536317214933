import styled from "styled-components"
import { device } from "breakpoints"
import { Button } from 'ui/Button'

export const SubscribePageContainer = styled.div`
  padding-top: 55px;
  padding-bottom: 15px;
  @media ${device.desktop} {
    padding-top: 70px;
    padding-bottom: 45px;
  }
  h3 {
    font-size: 24px;

    @media ${device.desktop} {
      font-size: 30px;
    }
  }

  button {
    @media ${device.desktop} {
      margin-top: 70px;
    }
  }
`

export const SubscribeForm = styled.form`
  
`;
export const SubscribeTitle = styled.h3`
  margin-bottom: 20px;
`

export const SubscribeSubtitle = styled.p`
  display: ${(props) => (props.submitted ? "none" : "blcok")};
  margin-bottom: 20px;
`

export const SubmitButton = styled(Button)`
  
`;