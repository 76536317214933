import React from "react"
import { Accordion, AccordionItem } from "ui/Accordion"
import { AccordionCustomContentWrapper } from "./style"
const AccordionEg = () => {
  return (
    <Accordion>
      <AccordionItem title="title1" startOpen>
        <AccordionCustomContentWrapper>
          <h3>Insert any content</h3>
          <p>
            Sed aliquam ultrices mauris. In enim justo, rhoncus ut, imperdiet a,
            venenatis vitae, justo. Aenean vulputate eleifend tellus.
          </p>
        </AccordionCustomContentWrapper>
      </AccordionItem>
      <AccordionItem title="title2">
        <AccordionCustomContentWrapper>
          <p>
            Fusce ac felis sit amet ligula pharetra condimentum. Nunc sed
            turpis. Aliquam lorem ante, dapibus in, viverra quis, feugiat a,
            tellus. Proin faucibus arcu quis ante.
          </p>
          <p>
            Duis lobortis massa imperdiet quam. Duis arcu tortor, suscipit eget,
            imperdiet nec, imperdiet iaculis, ipsum. Curabitur suscipit suscipit
            tellus. Nam quam nunc, blandit vel, luctus pulvinar, hendrerit id,
            lorem.
          </p>
        </AccordionCustomContentWrapper>
      </AccordionItem>
      <AccordionItem title="title3">
        <AccordionCustomContentWrapper>
          <p>
            Mauris turpis nunc, blandit et, volutpat molestie, porta ut, ligula.
            Phasellus tempus.
          </p>
          <p>
            Sed in libero ut nibh placerat accumsan. Etiam sollicitudin, ipsum
            eu pulvinar rutrum, tellus ipsum laoreet sapien, quis venenatis ante
            odio sit amet eros.
          </p>
        </AccordionCustomContentWrapper>
      </AccordionItem>
    </Accordion>
  )
}

export default AccordionEg
