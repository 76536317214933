import React, { useState } from "react"
import {
  AccordionItemWrapper,
  AccordionTrigger,
  AccordionDisplay,
} from "./style"
import { ReactComponent as DownArrow } from "./down-arrow.svg"
import { useDidMountEffect } from "helpers/useDidMountEffect"
const AccordionItem = ({
  title,
  className,
  children,
  setCurrentlyExpanded,
  currentlyExpanded,
  startOpen,
}) => {
  const [expanded, setExpanded] = useState(startOpen)

  const toggleExpand = () => {
    setExpanded(!expanded)

    if (setCurrentlyExpanded) {
      setCurrentlyExpanded && setCurrentlyExpanded(title)

      setCurrentlyExpanded(title)
    }
  }

  useDidMountEffect(() => {
    if (currentlyExpanded !== title) {
      setExpanded(false)
    }
    console.log("currentlyExpanded:", currentlyExpanded)
  }, [currentlyExpanded])

  return (
    <AccordionItemWrapper className={className}>
      <AccordionTrigger expanded={expanded} onClick={toggleExpand}>
        <h3 className="title">{title}</h3>
        <DownArrow className="arrow" />
      </AccordionTrigger>

      <AccordionDisplay expanded={expanded}>{children}</AccordionDisplay>
    </AccordionItemWrapper>
  )
}

export default AccordionItem
