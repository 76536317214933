import React, { useContext, useRef, useEffect } from "react"
import { PopUpContext } from "context/PopUpContext"
import { useEscHandler } from "helpers/useEscHandler"
import { useOutsiderAlerter } from "helpers/useOutsiderAlerter"
import { disableBodyScroll, enableBodyScroll } from "body-scroll-lock"
import {
  PopUpContainer,
  PopUpBackground,
  ClosePopUpButton,
  PopUpMain,
} from "./style"

const PopUp = () => {
  const { isPopUpOpen, setPopUpOpen } = useContext(PopUpContext)
  const popUpRef = useRef(null)

  const closePopUp = () => {
    setPopUpOpen(false)
  }
  useOutsiderAlerter(popUpRef, () => closePopUp())
  useEscHandler(() => closePopUp())

  useEffect(() => {
    const targetElement = popUpRef.current

    if (isPopUpOpen) {
      disableBodyScroll(targetElement)
    } else {
      enableBodyScroll(targetElement)
    }
  }, [isPopUpOpen])

  return (
    <PopUpContainer isPopUpOpen={isPopUpOpen}>
      <PopUpBackground />
      <ClosePopUpButton label="close pop up" onClick={closePopUp} />
      <PopUpMain ref={popUpRef}>
        <h3 style={{ marginBottom: "20px" }}>I'm a pop up yo.</h3>
        <p style={{ marginBottom: "15px" }}>
          top right button is a good example of an extended button.
        </p>
        <p>ESC key also closes this bad boy.</p>
      </PopUpMain>
    </PopUpContainer>
  )
}

export default PopUp
